@import "../../assets/styles/variables";
@import "../../assets/styles/resets";
@import "../../assets//styles/fonts";

.page {
  width: 100%;
  text-align: center;
  animation: fade 0.9s ease-in;
  overflow-x: hidden;

  header {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    color: $primary;
    // width: 350px;
    text-align: center;
    
    .heading {
      padding: 50px;

      h1 {

        @media screen and (max-width: 900px) {
          font-size: 4rem;
        }
      }

      hr {
        width: 65px;
        height: 4px;
        background-color: $secondary;
        border: none;
        margin: auto;
      }
    }
  }

  .carousel {
    display: flex;
    position: relative;
    justify-content: center;
    flex-direction: column;
    align-items: center;

    .buttonContainer {
      button {
        width: 100px;
        height: 100px;
        border: none;
        font-size: 2.5rem;
        background-color: $light;
        color: $primary;

        &:focus {
          outline: none;
          cursor: pointer;
        }
      }
    }

    $i: 0;
    @for $i from 0 to 26 {
      .cards_slider.active_slide_#{$i} #card-#{$i} {
        opacity: 1;
        transform: scale(1);
      }  
    }

    .cards_slider {
      position: relative;
      max-width: 350px;
      width: 100%;
      margin: 0 auto;

      @media screen and (max-width: 800px) {
        max-width: 250px;
      }
  
      .cards_slider_wrapper {
        display: flex;
        position: absolute;
        top: 0;
        overflow: hidden;
        transition: transform 300ms cubic-bezier(0.455, 0.03, 0.5151, 0.955);
        
        .headshot {
          width: 350px;
          height: auto;
          border-radius: 5px;
          flex: 1;
          opacity: 0.5;
          transform: scale(0.7);
          transition: opacity 300ms linear, 300ms cubic-bezier(0.455, 0.03, 0.5151, 0.955);

          @media screen and (max-width: 800px) {
            width: 250px;
            height: 250px;
          }

          @media screen and (max-width: 500px) {
            width: 250px;
            height: 300px;
          }
        }
        &:after {
          content: '';
          display: block;
          width: 100%;
          height: 300px;
          // outline: 5px solid $secondary;
          position: absolute;
          // top: 0;
          // left: 0;
        }
      }
    }
  }
  .intro {
    margin-top: 400px;
    /* Applies margin-top to safari browser only */
    @media not all and (min-resolution:.001dpcm){ @supports (-webkit-appearance:none) { 
      margin-top: 550px; 
    }}

    @media screen and (max-width: 800px) {
      margin-top: 450px;
    }

    @media screen and (max-width: 500px) {
      margin-top: 350px;
    }

    h2 {
      opacity: 0.7;
    }
    
    hr {
      width: 65px;
      height: 4px;
      background-color: $secondary;
      border: none;
      margin: auto;
    }
  }

  .trainee-bio {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 80%;
    margin: 100px auto;
    background-color: #FFF;
    border-radius: 5px;
    box-shadow: 0px 0px 20px 4px rgba(0, 0, 0, 0.1);
    padding: 40px;

    .bio-header {
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;

      .name-title {
        width: 60%;
        text-align: left;

        h4 {
          color: $secondary;
          font-size: 1.2rem;
          font-weight: 700;
          letter-spacing: 0.1em;
        }
      }

      .icons {
        display: flex;
        justify-content: space-between;
        align-items: center;
        font-size: 2.8rem;
        color: $primary;
        transform: scale(1);

        .github-icon {
          margin-right: 20px;
        }

        a {
          text-decoration: none;
          color: $primary;
        }
      }
    }

    p {
      text-align: left;
      font-weight: normal;
    }

    .quote {
      display: flex;
      width: 100%;
      font-family: 'Montserrat', sans-serif;
      font-style: italic;
      font-size: 1.2rem;
      text-align: justify;
      color: rgba(68, 68, 68, 0.4);
      margin: 30px 0;
    }

    .bio-container {
      display: flex;
      flex-direction: row;
      justify-content: space-between;

      .left {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        width: 70%;

        .bio {
          color: $dark;
          font-size: 1.2rem;
          font-family: 'Montserrat', sans-serif;
          text-align: justify;
          line-height: 1.5em;
          font-weight: 500;
          // height: 70%;
          margin-top: 20px;
          white-space: pre-line;
        }

        .buttonContainer {
          display: flex;
          // justify-content: space-between;
          align-items: center;
          margin-top: 50px;

          .portfolio-btn,
          .cv-btn {
            width: 180px;
            padding: 20px;
            border-radius: 5px;
            font-family: 'Open Sans', sans-serif;
            font-size: 1rem;
            font-weight: 700;
            letter-spacing: 0.2em;
            cursor: pointer;
            transform: scale(1);

            &:hover {
              transform: scale(1.01);
            }
          }

          .portfolio-btn {
            background-image: linear-gradient(to right, #f43268, #f74267, #f94f66, #fb5c66, #fc6767);
            box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.2);
            border: none;
            color: white;
            opacity: 0.9;
            margin-right: 30px;

            &:hover {
              opacity: 1.0;
            }
          }

          .cv-btn {
            border: 2px solid #f74267;
            color: #f74267;
            background-color: white;
          }
        }
      }
      .image_wrapper {
        width: 300px;
        margin-left: 30px;
        height: auto;

        .bio-image {
          width: 100%;
          border-radius: 5px;
        }
      }
    }
  }
}

@media screen and (max-width: 800px) {

  .page {
    .pageHeader {
      height: 40vh;
      display: flex;
      h1 {
        font-size: 2.3rem;
        width: 90%;
      }
    }
  
    .intro {
      h1 {
        margin-top: 0;
        font-size: 1.7rem;
      }
      hr {
        width: 40px;
      }
    }
  
    .trainee-bio {
      padding: 30px;
      .bio-header {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 100%;
  
        .name-title {
          display: flex;
          flex-direction: column;
          width: 100%;
          align-items: center;
          h2 {
            font-size: 1.2rem;
          }
          h4 {
            font-size: 1rem;
            margin-bottom: 10px;
          }
        }
        .icons {
          width: 100%;
          font-size: 1.2rem;
          justify-content: center;
          margin: 10px 0;
        }
      }
      .quote {
        font-size: 1rem;
      }
      .bio-container {
        .left {
          width: 100%;
  
          .bio {
            font-size: 0.8rem;
          }
          .buttonContainer {
            display: flex;
            justify-content: space-evenly;
  
            .portfolio-btn,
            .cv-btn {
              width: 110px;
              font-size: .6rem;
            }

            .portfolio-btn {
              margin-right: 10px;
            }
          }
        }
      }
      .image_wrapper {
        display: none;
      }
    }
  }
  }
  

  @media screen and (max-width: 1000px) {
    .trainee-bio {
      .bio-container {
        .left {
          width: 100%;
        }

        .buttonContainer {
          display: flex;
          justify-content: space-evenly;
        }
      }

      .image_wrapper {
        display: none;
      }
    }

    
  }

  @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
    .bio-container {
      word-wrap: break-word !important;
      width: 100%;
    }
}