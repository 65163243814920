@import "../../assets/styles/variables";
@import "../../assets/styles/resets";
@import "../../assets//styles/fonts";

.trainee {
  display: flex;
  flex-direction: column;
  height: 400px;
  width: 100%;
  text-align: center;
  margin: auto;

  .imageContainer {
    display: flex;
    width: 220px;
    height: 220px;
    margin: 0 auto;
    position: relative;
    justify-content: center;

    .image{
      display: block;
      width: 100%;
      border-radius: 50%;
    }

    .imageOverlay {
      display: flex;
      justify-content: center;
      align-items: center;
      position: absolute;
      top: 0;
      bottom: 0;
      background-color: $secondary;
      color: $light;
      opacity: 0;
      width: 100%;
      border-radius: 50%;
      transition: .3s ease-in;

      &:hover {
        cursor: pointer;
        opacity: 0.3;
      }

      p {
        font-size: 1.8rem;

        &:hover {
          text-decoration: underline;
        }
      }
    }
  }

  h4 {
    color: $secondary;
    margin-top: 20px;
    font-size: 1.2em;
  }

  p {
    font-family: 'Open Sans', sans-serif;
    font-size: 10px;
    font-weight: 400;
  }
  
}