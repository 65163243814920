@import "../../assets/styles/variables";
@import "../../assets/styles/resets";
@import "../../assets//styles/fonts";

.footer {
  background-color: $primary;
  width: 100%;
  position: fixed;
  background-attachment: scroll;
  background-position: 0% 0%;
  bottom: 0;
  z-index: 1000;
  animation: fade 0.9s ease-in;

  .footer_wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 90%;
    height: 8vh;
    margin: 0 auto;

    .footer_logo {
  
      img {
        width: 90px;
        height: 30px;
      }
    }

    .footer_links {
      // width: 20%;
      display: flex;
      justify-content: space-between;

      a {
        text-decoration: none;
  
        h5 {
          color: $light;
          position: relative;

          &::after {
            content: '';
            position: absolute;
            left: 0;
            bottom: -30%;
            width: 100%;
            height: 1px;
            background-color: $secondary;
            transform: scaleX(0);
            transform-origin: right;
            transition: transform 250ms ease-in;
          }

          &:hover {
            &::after {
              transform: scaleX(1.0);
              transform-origin: left;
            }
          }
        }
      }

      .hire_link {
        margin-right: 20px;
      }
    }
  }
}

// @media screen and (max-width: 1100px) {
//   .footer_links {
//     font-size: 0.8rem;
//   }
// }

// @media screen and (max-width: 400px) {
//   .footer {
//     .footer_wrapper {
//       .footer_logo {
//         img {
//           width: 50px;
//           height: auto;
//         }
//       }
//       .footer_links {
//         font-size: 0.5rem;
//       }
//     }
//   }
// }