@import "../../assets/styles/variables";
@import "../../assets/styles/resets";
@import "../../assets//styles/fonts";

.navbar {
  width: 100%;
  background-color: $light;
  animation: fade 0.9s ease-in;

  .navWrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 90%;
    height: 12vh;
    margin: 0 auto;

    .logo_container {  
      .logo {
        width: 120px;
        margin: 50px 0px;
      }
    }
    .iconWrapper {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 20px;
      height: 20px;
  
      .gridIcon {
        width: 100%;

        &:hover {
          fill: $secondary;
        }
      }
    }
  }
}
  // .nav_items {
  //   width: 55%;
  //   display: flex;
  //   justify-content: space-around;
  //   align-items: center;
  //   letter-spacing: 0.1em;
  //   font-size: 0.8rem;

  //   a {
  //     text-decoration: none;

  //     h5 {
  //       color: $primary;
  //       position: relative;

  //       &::after {
  //         content: '';
  //         position: absolute;
  //         left: 0;
  //         bottom: -140%;
  //         width: 100%;
  //         height: 1px;
  //         background-color: $secondary;
  //         transform: scaleX(0);
  //         transform-origin: right;
  //         transition: transform 250ms ease-in;
  //       }

  //       &:hover {
  //         &::after {
  //           transform: scaleX(1);
  //           transform-origin: left;
  //         }
  //       }
  //     }
  //   }